<template>
  <auth-card btn-classes="full-width"
    :show-close-btn="true">
    <template #description>
      <el-form ref="companyTradingNameForm"
        class="text-align-left"
        :model="companyTradingNameForm"
        :rules="companyTradingNameRules"
        label-position="top">
        <p>
          {{ $t('authentication.company-trading-name-instructions') }}
        </p>

        <el-form-item :label="$t('company.company-trading-name')"
          for="company-trading-name"
          prop="tradingName">
          <el-input id="company-trading-name"
            v-model="companyTradingNameForm.tradingName"
            :placeholder="$t('company.trading-name')"
            name="tradingName"
            autocomplete="off">
            <template #append>
              <el-popover placement="top"
                :title="$t('company.company-trading-name')"
                :content="$t('tooltips.company-trading-name')"
                width="400"
                trigger="hover">
                <span slot="reference">
                  <i class="el-icon-info color-info"
                    aria-hidden="true" />
                </span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item prop="agreement">
          <el-checkbox id="agreeToTerms"
            v-model="companyTradingNameForm.agreement"
            name="agreeToTerms">
            <span class="terms">
              {{ $t('authentication.agree-to') }}
              <a href="https://www.megaport.com/legal/privacy-policy/"
                target="_blank"
                rel="noopener">{{ $t('general.privacy-policy') }}</a>,
              <a href="https://www.megaport.com/legal/site-terms"
                target="_blank"
                rel="noopener">{{ $t('general.site-terms') }}</a>,
              <a href="https://www.megaport.com/legal/acceptable-use-policy/"
                target="_blank"
                rel="noopener">{{ $t('general.acceptable-use') }}</a>
            </span>
          </el-checkbox>
        </el-form-item>
      </el-form>

      <el-button class="full-width"
        @click="register">
        <div class="flex-row-centered flex-justify-center">
          <div>{{ $t('authentication.continue-to-megaport') }}</div>
        </div>
      </el-button>
    </template>
  </auth-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import AuthCard from '@/components/ui-components/AuthCard.vue'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

export default {
  name: 'CompanyTradingName',

  components: {
    'auth-card': AuthCard,
  },

  data() {
    return {
      loader: null,
      companyTradingNameForm: {
        tradingName: '',
        agreement: false,
      },
      companyTradingNameRules: {
        tradingName: { required: true, message: this.$t('validations.required', { thing: 'Company trading name' }), trigger: 'blur' },
        agreement: { required: true, validator: this.validateBooleanTrue, trigger: 'change' },
      },
    }
  },

  computed: {
    ...mapState('Services', ['services']),
  },

  methods: {
    ...mapActions('Services', ['getMyServices']),
    ...mapMutations('Notifications', ['notifyBad']),
    ...mapActions('Company', ['postCompany']),
    /**
     * Validate agreement checkbox is checked
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateBooleanTrue(_rule, value, callback) {
      if (typeof value !== 'boolean' || !value) {
        callback(new Error(this.$t('validations.terms-conditions')))
      } else {
        callback()
      }
    },
    /**
     * Sign up the user
     */
    register() {
      this.$refs['companyTradingNameForm'].validate(valid => {
        if (valid) {
          const { tradingName: companyName } = this.companyTradingNameForm
          this.loader = this.$loading({
              lock: true,
              text: this.$t('authentication.logging-in'),
              background: 'rgba(255, 255, 255, 0.9)',
            })
          this.postCompany({ tradingName: companyName }).then(this.loadServices)
        } else {
          this.notifyBad({
            title: this.$t('authentication.signup-failed'),
          })
        }
      })
    },

    loadServices() {
      // We need to know how many services they have before logging in
      // so as to redirect them to dashboard or services
      this.loader.setText(this.$t('services.loading-services'))

      this.getMyServices()
        .finally(() => {
          this.loader.close()
          this.$router.push(resolveServicesPage())
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-icon-info {
  font-size: 1.6rem;
  line-height: initial;
}
</style>
